@import "https://fonts.googleapis.com/css?family=Fira+Mono";
@import "https://fonts.googleapis.com/css?family=Just+Me+Again+Down+Here";
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: "Fira Mono", "Courier New", Courier, monospace;
}
:root {
  --minsize: 300px;
}
body {
  width: 100vw;
  height: 100vh;
  /* scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  touch-action: none; */
}
#root {
  width: 100vw;
  height: 100vh;
}
