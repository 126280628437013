.container-message {
  width: 100vw;
  height: 25vh;
  position: absolute;
  z-index: 21;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.message {
  width: 300px;
  height: 70px;
  top: -160px;
  border-radius: 15px;
  position: absolute;
  transition: transform 400ms;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.message.active {
  transform: translate(0%, 160px);
}

.message .cross {
  position: absolute;
  right: 0;
  top: 0;
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.message .cross::after,
.message .cross::before {
  content: "";
  position: absolute;
  width: 22px;
  height: 4px;
  background: #000;
  border-radius: 20%;
}
.message .cross::after {
  transform: rotate(45deg);
}
.message .cross::before {
  transform: rotate(-45deg);
}
