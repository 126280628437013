:root {
  --icon-size: 40px;
}
.bottom-bar {
  position: absolute;
  bottom: 5%;
  left: 0;
  width: 100vw;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: var(--minsize);
  user-select: none;
}
.bottom-bar .tool {
  background: none;
  width: 70px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  opacity: 1;
  transition: opacity 200ms;
}
.bottom-bar .tool.active {
  opacity: 0.5;
}
.bottom-bar .tool .icon-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 60%;
}
.bottom-bar .tool .icon {
  position: absolute;
  width: var(--icon-size);
  height: var(--icon-size);
  transform: translate(0, 0);
  outline: none;
  border-radius: 50%;
  box-shadow: 0px 3px 0px #000;
  transition: all 200ms;
}
.bottom-bar .tool .icon > div {
  width: 100%;
  height: 100%;
  position: absolute;
}
.bottom-bar .tool:not(.active):hover .icon {
  position: absolute;
  transform: translate(0, -2px);
  box-shadow: 0px 5px 0px #000;
}

.bottom-bar .tool span {
  font-size: 0.8rem;
  margin: 5px 0 0 0;
  text-align: center;
  width: 70px;
  height: 20px;
  color: #000;
}
